import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { createdAtSort, isSignedIn, getLocalhostCognitoUsername } from '../utils/helpers'
import { API, graphqlOperation } from 'aws-amplify'
import { listDeliverys, listDrivers } from '../graphql/queries'
import AppLoader from './AppLoader'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../actions'

const useStyles = makeStyles({
  container: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiPaper-root': {
      width: '100%',
      boxShadow: '0px 0px 1px gray',
    },
    '& .MuiTableCell-head, .MUIDataTableHeadCell': {
      background: '#eee',
      fontWeight: 'bold',
    },
    '& .MuiTableRow-root:nth-child(even)': {
      background: '#eee',
    },
  },
  link: {
    margin: 20,
  },
  back: {
    margin: 20,
    padding: 10,
    border: '1px solid lightgray',
    borderRadius: 30,
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'background-color 0.25s ease',
    '&:hover': {
      backgroundColor: '#efefef',
    },
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div': {
      outline: 'none',
    },
    '& img': {
      outline: 'none',
      maxWidth: '80%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgLink: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

const DeliveriesDashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [deliveries, setDeliveries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const driver = useSelector(state => state.appReducer.driver)

  useEffect(() => {
    if (isSignedIn() && !driver?.id) {
      dispatch(Actions.fetchDriver(getLocalhostCognitoUsername()))
    }
  }, [dispatch, driver])

  const calcStatus = status => {
    switch (status) {
      case 'completed':
        return 'הסתיים'
      case 'picked':
        return 'נאסף'
      case 'pickup':
        return 'נהג נרשם למשלוח'
      case 'new':
        return 'משלוח חדש'
      default:
        return ''
    }
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      API.graphql(graphqlOperation(listDeliverys, { limit: 100000 })),
      API.graphql(graphqlOperation(listDrivers, { limit: 100000 })),
    ])
      .then(([deliveriesData, driversData]) => {
        const deliveries = deliveriesData.data.listDeliverys.items.filter(d => !d._deleted)
        const drivers = driversData.data.listDrivers.items.filter(d => !d._deleted)

        setDeliveries(
          deliveries.map(delivery => ({
            ...delivery,
            // date: moment(delivery.date).format('DD-MM-YYYY'),
            driverName: (drivers.find(d => d.id === delivery.driverId) || {}).name,
            status: calcStatus(delivery.status),
          }))
        )
      })
      .catch(console.log)
      .finally(() => setIsLoading(false))
  }, [])

  const columns = [
    { name: 'title', label: 'כותרת' },
    {
      name: 'date',
      label: 'תאריך המשלוח',
      options: {
        sortDirection: 'desc',
        customBodyRender: (value) => (
          <span>{moment(value).format('DD-MM-YYYY')}</span>
        ),
      }
    },
    { name: 'pickupAddress', label: 'כתובת איסוף' },
    { name: 'deliveryAddress', label: 'כתובת יעד' },
    { name: 'senderName', label: 'שם השולח' },
    { name: 'senderPhone', label: 'טלפון השולח' },
    { name: 'recipientName', label: 'שם המקבל' },
    { name: 'recipientPhone', label: 'טלפון המקבל' },
    { name: 'driverName', label: 'שם השליח' },
    { name: 'cost', label: 'תשלום' },
    { name: 'status', label: 'סטאטוס' },
  ]

  const options = {
    textLabels: {
      body: {
        noMatch: 'לא נמצאו נתונים',
      },
    },
    filterType: 'search',
    downloadOptions: {
      filename: 'Deliveries.csv',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    print: false,
    selectableRows: 'single',
    customSort: createdAtSort(columns),
  }

  return isLoading ? (
    <AppLoader open={isLoading} />
  ) : (
    <div className={classes.container}>
      <MUIDataTable
        title={'היסטוריית משלוחים'}
        data={deliveries}
        columns={columns}
        options={options}
      />
    </div>
  )
}

export default DeliveriesDashboard
