import React from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Box } from '@material-ui/core'

const Confirmation = ({ isOpen, handleClose, handleConfirm }) => {
  return (
    <Box>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          שים לב!
        </DialogTitle>
        <DialogContent>
          האם אתה בטוח שברצונך למחוק את המשלוח? פעולה זו אינה ניתנת לביטול
        </DialogContent>
        <DialogActions>
          <Button style={{ background: '#3498db', color: 'white' }} onClick={handleConfirm}>
            אישור
          </Button>
          <Button onClick={handleClose}>
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Confirmation