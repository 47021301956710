export const listDrivers = /* GraphQL */ `
query ListDrivers(
  $filter: ModelDriverFilterInput
  $limit: Int
  $nextToken: String
) {
  listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      name
      idNumber
      businessType
      age
      email
      phone
      city
      vehicleType
      licensePhoto {
        bucket
        region
        key
        mimeType
      }
      smsEnabled
      agreedToTerms
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      deliveries(limit: 10000) {
        items {
          id
        }
        nextToken
        startedAt
      }
    }
    nextToken
    startedAt
  }
}
`