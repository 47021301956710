import React, { useState } from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import moment from 'moment'
import PersonIcon from '@material-ui/icons/PersonRounded'
import LocationIcon from '@material-ui/icons/LocationOnRounded'
import PhoneIcon from '@material-ui/icons/PhoneEnabledRounded'
import { generateWazeSearch } from '../utils/helpers'
import Actions from '../actions'
import config from '../aws-exports'
import { useDispatch, useSelector } from 'react-redux'
import ImageUpload from './ImageUploader'
import { Storage } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles({
  paper: {
    minWidth: '90%',
    '@media (min-width: 1280px)': {
      minWidth: 400,
    },
  },
  input: {
    width: '100%',
    marginBottom: 30,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    '& h2': {
      fontSize: '20px !important',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  section: {
    background: '#eee',
    padding: 10,
    borderRadius: '5px 5px 40px 5px',
    marginBottom: 15,
  },
  dialogLabel: {
    marginBottom: 5,
    color: '#34495e',
    fontSize: 15,
    fontWeight: 'bold',
  },
  dialogValue: {
    marginBottom: 17,
    margin: '0 10px',
  },
  value: {
    margin: '0 10px',
  },
  primary: {
    background: '#2ecc71',
    color: 'white',
    fontSize: 16,
    flex: 1,
  },
  secondary: {
    color: 'black',
    fontSize: 16,
  },
})

const OngoingDeliveryInfo = ({ open, handleClose, delivery }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [deliveryImage, setDeliveryImage] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const isLoading = useSelector(state => state.appReducer.deliverySaving)

  const handleCloseDialog = () => {
    handleClose()
  }

  const generateTextIcon = (Icon, text, color, url) => (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
      <Icon />
      {url ? (
        <a href={url} style={{ textDecoration: 'none' }}>
          <span className={classes.value} style={{ color: color || 'black' }}>
            {text}
          </span>
        </a>
      ) : (
        <span className={classes.value}>{text}</span>
      )}
    </div>
  )

  return !!delivery ? (
    <Dialog open={!!open} onClose={handleCloseDialog} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>{delivery.title}</DialogTitle>
      <DialogContent>
        <div className={classes.dialogLabel}>תאריך ושעה</div>
        <div className={classes.dialogValue}>
          {moment(delivery.date).format('DD-MM-YYYY')}, {delivery.fromHour}-{delivery.toHour}
        </div>
        <div className={classes.section}>
          <div className={classes.dialogLabel}>פרטי איסוף</div>
          {generateTextIcon(PersonIcon, delivery.senderName)}
          {generateTextIcon(
            PhoneIcon,
            delivery.senderPhone + ' (חייג)',
            '#2980b9',
            `tel:${delivery.senderPhone}`
          )}
          {generateTextIcon(
            LocationIcon,
            delivery.pickupAddress + ' (נווט)',
            '#2980b9',
            generateWazeSearch(delivery.pickupAddress)
          )}
        </div>

        <div className={classes.section}>
          <div className={classes.dialogLabel}>פרטי יעד</div>
          {generateTextIcon(PersonIcon, delivery.recipientName)}
          {generateTextIcon(
            PhoneIcon,
            delivery.recipientPhone + ' (חייג)',
            '#2980b9',
            `tel:${delivery.recipientPhone}`
          )}
          {generateTextIcon(
            LocationIcon,
            delivery.deliveryAddress + ' (נווט)',
            '#2980b9',
            generateWazeSearch(delivery.deliveryAddress)
          )}
        </div>

        {delivery.note && (
          <>
            <div className={classes.dialogLabel}>הערה</div>
            <div className={classes.dialogValue}>{delivery.note}</div>
          </>
        )}
        {delivery.status === 'picked' && (
          <>
            <div className={classes.dialogLabel}>העלה תמונה מהמסירה (אופציונלי)</div>
            <ImageUpload handleImageUpload={image => setDeliveryImage(image)} />
          </>
        )}
        <DialogActions>
          {isLoading || isUploading ? (
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="secondary" />
            </div>
          ) : delivery.status === 'pickup' ? (
            <Button
              variant="contained"
              className={classes.primary}
              onClick={() =>
                dispatch(
                  Actions.updateDelivery({
                    id: delivery.id,
                    _version: delivery._version,
                    status: 'picked',
                  })
                )
              }
            >
              אספתי את המשלוח
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.primary}
              onClick={() => {
                const uuid = uuidv4()
                setIsUploading(true)

                if (deliveryImage) {
                  Storage.put(uuid, deliveryImage)
                    .then(() => {
                      setIsUploading(false)
                      dispatch(
                        Actions.updateDelivery({
                          id: delivery.id,
                          _version: delivery._version,
                          status: 'completed',
                          deliveryImage: {
                            bucket: config.aws_user_files_s3_bucket,
                            region: config.aws_user_files_s3_bucket_region,
                            key: uuid,
                            mimeType: 'image',
                          },
                        })
                      )
                    })
                    .finally(() => setIsUploading(false))
                } else {
                  dispatch(
                    Actions.updateDelivery({
                      id: delivery.id,
                      _version: delivery._version,
                      status: 'completed',
                    })
                  )
                }
              }}
            >
              מסרתי את המשלוח
            </Button>
          )}

          <Button variant="contained" className={classes.secondary} onClick={handleCloseDialog}>
            סגור
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  ) : null
}

export default OngoingDeliveryInfo
