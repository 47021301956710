import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import AppHeader from './AppHeader'
import { Route, Redirect, Switch, useHistory, useLocation } from 'react-router'
import * as ROUTES from '../consts/routerConsts'
import SignIn from './SignIn'
import ResetPassword from './ResetPassword'
import SignUp from './SignUp'
import DeliveriesList from './Deliveries'
import NewDelivery from './NewDelivery'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../actions'
import { Auth } from 'aws-amplify'
import Settings from './Settings'
import DriverInfo from './DriverInfo'
import DriversDashboard from './DriversDashboard'
import DeliveriesDashboard from './DeliveriesDashboard'
import AppSnackbar from './AppSnackbar'

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
})

const App = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const driver = useSelector(state => state.appReducer.driver)

  useEffect(() => {
    const username = localStorage.getItem('driverUsername')
    if (!driver?.id && !!username) {
      dispatch(Actions.fetchDriver(username))
    }
  }, [dispatch, driver])

  useEffect(() => {
    const checkIfSignedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        dispatch(Actions.resignInReceived(user.attributes.sub))
      } catch (err) {
        history.push(ROUTES.DELIVERIES)
      }
    }

    checkIfSignedIn()
  }, [history, dispatch])

  useEffect(() => {
    if (location.pathname !== ROUTES.ROOT) {
      dispatch(Actions.setPostAuthLocation(location.pathname))
    }
  }, [location, history, dispatch])

  return (
    <div className={classes.container}>
      <AppHeader />
      <AppSnackbar />
      <Switch>
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        <Route path={ROUTES.DRIVER_INFO} component={DriverInfo} />
        <Route path={ROUTES.NEW_DELIVERY} component={NewDelivery} />
        <Route path={ROUTES.DELIVERIES} component={DeliveriesList} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <Route path={ROUTES.SETTINGS} component={Settings} />
        <Route path={ROUTES.DRIVERS_DASHBOARD} component={DriversDashboard} />
        <Route path={ROUTES.DELIVERIES_DASHBOARD} component={DeliveriesDashboard} />
        <Redirect to={ROUTES.DELIVERIES} />
      </Switch>
    </div>
  )
}

export default App
