/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:597d2390-fa75-4e44-b3f9-6f2ad7317c90",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JhM73ECPz",
    "aws_user_pools_web_client_id": "6v702vuijm5fe1rparrn40v0d8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://grogb5xz3je7dhhgiirn2iuj5m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ce7vpl3ldbcd5cr6hbaenttzlq",
    "aws_cloud_logic_custom": [
        {
            "name": "generalRest",
            "endpoint": "https://klmi0ka911.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "tiktak-main181851-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "3bed27924eb74fbb8d681bd7ed339f4b",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
