import Actions from '../actions'
import { ofType } from 'redux-observable'
import {
  mergeMap,
  pluck,
  switchMap,
  catchError,
  tap,
  map,
  mapTo,
  withLatestFrom,
  filter,
} from 'rxjs/operators'
import { from, of } from 'rxjs'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { driversByUsername } from '../graphql/queries'
import * as ROUTES from '../consts/routerConsts'
import { push } from 'connected-react-router'
import { mapToCognitoUsername } from '../utils/helpers'

const setUserGroups = groups => {
  sessionStorage.setItem(btoa(btoa('userGroups')), btoa(btoa(groups)))
}

export const requestSignInEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_SIGNIN),
    pluck('payload'),
    map(({ username, password }) => ({ username: mapToCognitoUsername(username), password })),
    switchMap(({ username, password }) =>
      from(Auth.signIn({ username, password })).pipe(
        tap(user => setUserGroups(user.signInUserSession?.idToken?.payload['cognito:groups'])),
        tap(user => localStorage.setItem('driverUsername', user.username)),
        tap(user => sessionStorage.setItem('driverUsername', user.username)),
        switchMap(user =>
          from(API.graphql(graphqlOperation(driversByUsername, { username: user.username }))).pipe(
            map(({ data }) => data.driversByUsername.items[0]),
            mergeMap(driver => of(Actions.signInReceived(driver))),
            catchError(err => of(Actions.asyncRequestFailed({ err })))
          )
        ),
        catchError(err => {
          if (err.code === 'PasswordResetRequiredException') {
            return of(Actions.forgotPasswordReceived())
          } else if (
            (err.code === 'NotAuthorizedException' &&
              err.message === 'Incorrect username or password.') ||
            err.code === 'UserNotFoundException'
          ) {
            return of(
              Actions.asyncRequestFailed({
                snackbarOpen: true,
                snackbarText: 'שם המשתמש או הסיסמא אינם נכונים',
                snackbarType: 'error',
                snackbarDuration: 3000,
              })
            )
          } else {
            return of(Actions.asyncRequestFailed({ err }))
          }
        })
      )
    )
  )

export const resignInEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESIGN_IN_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      username: action.payload,
      redirectTo: state.appReducer.postAuthPath ?? ROUTES.DELIVERIES,
    })),
    tap(({ username }) => sessionStorage.setItem('driverUsername', username)),
    tap(({ username }) => localStorage.setItem('driverUsername', username)),
    switchMap(({ redirectTo }) =>
      from(Auth.currentAuthenticatedUser()).pipe(
        tap(({ signInUserSession }) =>
          setUserGroups(signInUserSession?.idToken?.payload['cognito:groups'])
        ),
        map(() => push(redirectTo))
      )
    )
  )

export const signInReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.SIGNIN_RECEIVED), mapTo(push(ROUTES.DELIVERIES)))

export const requestSignUpEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_SIGNUP),
    pluck('payload'),
    switchMap(({ username, password, email }) =>
      from(
        Auth.signUp({ username: mapToCognitoUsername(username), password, attributes: { email } })
      ).pipe(
        mergeMap(response => of(Actions.signUpReceived({ success: response }))),
        catchError(err =>
          of(
            Actions.asyncRequestFailed({
              err,
              snackbarOpen: true,
              snackbarText:
                err.code === 'UsernameExistsException'
                  ? 'מספר טלפון זה כבר רשום במערכת. עבור להתחברות או הירשם עם מספר אחר'
                  : err.code === 'InvalidParameterException'
                  ? 'הסיסמא חייבת להיות לפחות באורך 8 תווים'
                  : 'ארעה שגיאה לא צפויה',
              snackbarType: 'error',
              snackbarDuration: 3000,
            })
          )
        )
      )
    )
  )

export const signupReceivedErrorEpic = action$ =>
  action$.pipe(
    ofType(Actions.SIGNUP_RECEIVED),
    filter(signUpResults => !!signUpResults.error),
    mergeMap(({ error }) => of(Actions.signUpWithErrors(error)))
  )

export const confirmSignUpEpic = action$ =>
  action$.pipe(
    ofType(Actions.CONFIRM_SIGNUP),
    pluck('payload'),
    switchMap(({ username, password, code }) =>
      from(Auth.confirmSignUp(mapToCognitoUsername(username), code)).pipe(
        mergeMap(d => of(Actions.confirmSignIn({ username, password }))),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const confirmSignInEpic = action$ =>
  action$.pipe(
    ofType(Actions.CONFIRM_SIGNIN),
    pluck('payload'),
    switchMap(({ username, password }) =>
      from(Auth.signIn(mapToCognitoUsername(username), password)).pipe(
        mergeMap(() => of(Actions.confirmSignInReceived())),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const confirmSignInReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CONFIRM_SIGNIN_RECEIVED),
    pluck('payload'),
    mapTo(push(ROUTES.DRIVER_INFO))
  )

export const requestSignOutEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_SIGNOUT),
    switchMap(() =>
      from(Auth.signOut()).pipe(
        tap(() => sessionStorage.removeItem('driverUsername')),
        tap(() => sessionStorage.removeItem(btoa(btoa('userGroups')))),
        tap(() => localStorage.removeItem('driverUsername')),
        mergeMap(() => of(Actions.signOutReceived())),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const signOutReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.SIGNOUT_RECEIVED), mapTo(push(ROUTES.ROOT)))

export const requestForgotPasswordEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_FORGOT_PASSWORD),
    pluck('payload'),
    switchMap(username =>
      from(Auth.forgotPassword(mapToCognitoUsername(username))).pipe(
        mergeMap(() => of(Actions.forgotPasswordReceived())),
        catchError(err => of(Actions.asyncRequestFailed({ err,
          snackbarOpen: true,
                snackbarText: err.code === 'UserNotFoundException' ? 'מספר טלפון זה לא קיים במערכת' : 'ארעה שגיאה בלתי צפויה',
                snackbarType: 'error',
                snackbarDuration: 3000,
        })))
      )
    )
  )

export const forgotPasswordReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.FORGOT_PASSWORD_RECEIVED), mapTo(push(ROUTES.RESET_PASSWORD)))

export const requestResetPasswordEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_RESET_PASSWORD),
    pluck('payload'),
    switchMap(({ username, code, password }) =>
      from(Auth.forgotPasswordSubmit(mapToCognitoUsername(username), code, password)).pipe(
        mergeMap(res => of(Actions.resetPasswordReceived(res))),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )
