import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import DeliveryItem from './DeliveryItem'
import Actions from '../actions'
import _ from 'lodash'
import AppLoader from './AppLoader'
import { isSignedIn, getLocalhostCognitoUsername } from '../utils/helpers'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deliveriesList: {
    marginTop: 30,
    width: '80%',
    '@media (min-width: 1280px)': {
      width: 600,
    },
  },
  hello: {
    marginTop: 30,
    textAlign: 'center',
  },
  more: {
    background: '#eee',
    padding: 15,
    borderRadius: 10,
  },
})

const Deliveries = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const deliveries = useSelector(state => state.appReducer.deliveries)
  const driver = useSelector(state => state.appReducer.driver)
  const isLoading = useSelector(state => state.appReducer.deliveriesLoading)

  const ongoing = deliveries.filter(d => d.driverId === driver?.id && d.status !== 'completed')
  const free = deliveries
    .filter(d => !d.driverId)
    .sort((A, B) => (moment(A.date).isBefore(moment(B.date)) ? -1 : 1))
    .sort((A, B) => (moment(A.createdAt).isBefore(moment(B.createdAt)) ? 1 : -1))
  const taken = deliveries.filter(d => !!d.driverId && d.driverId !== driver?.id)
  const completed = deliveries
    .filter(d => d.status === 'completed')
    .sort((A, B) => (moment(A.date).isBefore(moment(B.date)) ? -1 : 1))
    .sort((A, B) => (moment(A.createdAt).isBefore(moment(B.createdAt)) ? 1 : -1))
    .slice(0, 5)

  const completedAndTaken = [...taken, ...completed]
    .sort((A, B) => (moment(A.date).isBefore(moment(B.date)) ? -1 : 1))
    .sort((A, B) => (moment(A.createdAt).isBefore(moment(B.createdAt)) ? 1 : -1))
  
    const combinedDeliveries = [...ongoing, ...free, ...completedAndTaken]

  useEffect(() => {
    if (isSignedIn() && !driver?.id) {
      dispatch(Actions.fetchDriver(getLocalhostCognitoUsername()))
    } else {
      dispatch(Actions.fetchDeliveries())
    }
  }, [dispatch, driver])

  return isLoading || (isSignedIn() && !driver?.id) ? (
    <AppLoader open={isLoading} />
  ) : (
    <div className={classes.container}>
      {driver.id && (
        <div className={classes.hello}>
          <b style={{ fontSize: 20 }}>שלום {driver.name}</b>
          {ongoing.length > 0 && <p>יש לך {ongoing.length} משלוחים בביצוע</p>}
          <p className={classes.more}>
            ישנם עוד <b>{deliveries.filter(d => !d.driverId).length}</b> משלוחים פנויים כעת
          </p>
        </div>
      )}
      <div className={classes.deliveriesList}>
        {_.uniq(combinedDeliveries, 'id').map((delivery, i) => (
          <DeliveryItem key={i} delivery={delivery} />
        ))}
      </div>
    </div>
  )
}

export default Deliveries

/* .sort((A, B) => {
            if (A.driverId && !B.driverId) {
              return 1
            } else if (!A.driverId && B.driverId) {
              return -1
            } else if (A.driverId && B.driverId) {
              return A.createdAt > B.createdAt ? 1 : -1
            } else if (!A.driverId && !B.driverId) {
              return A.createdAt > B.createdAt ? 1 : -1
            }
            return 1
          }) */
