/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDelivery = /* GraphQL */ `
  query GetDelivery($id: ID!) {
    getDelivery(id: $id) {
      id
      username
      a_driver
      title
      senderName
      senderPhone
      recipientName
      recipientPhone
      date
      fromHour
      toHour
      pickupAddress
      deliveryAddress
      cost
      note
      driverId
      deliveryImage {
        bucket
        region
        key
        mimeType
      }
      isUrgent
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDeliverys = /* GraphQL */ `
  query ListDeliverys(
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        a_driver
        title
        senderName
        senderPhone
        recipientName
        recipientPhone
        date
        fromHour
        toHour
        pickupAddress
        deliveryAddress
        cost
        note
        driverId
        deliveryImage {
          bucket
          region
          key
          mimeType
        }
        isUrgent
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deliveriesByUsername = /* GraphQL */ `
  query DeliveriesByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveriesByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        a_driver
        title
        senderName
        senderPhone
        recipientName
        recipientPhone
        date
        fromHour
        toHour
        pickupAddress
        deliveryAddress
        cost
        note
        driverId
        deliveryImage {
          bucket
          region
          key
          mimeType
        }
        isUrgent
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const deliveriesByDriverId = /* GraphQL */ `
  query DeliveriesByDriverId(
    $driverId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveriesByDriverId(
      driverId: $driverId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        a_driver
        title
        senderName
        senderPhone
        recipientName
        recipientPhone
        date
        fromHour
        toHour
        pickupAddress
        deliveryAddress
        cost
        note
        driverId
        deliveryImage {
          bucket
          region
          key
          mimeType
        }
        isUrgent
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeliveries = /* GraphQL */ `
  query SyncDeliveries(
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliveries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        a_driver
        title
        senderName
        senderPhone
        recipientName
        recipientPhone
        date
        fromHour
        toHour
        pickupAddress
        deliveryAddress
        cost
        note
        driverId
        deliveryImage {
          bucket
          region
          key
          mimeType
        }
        isUrgent
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDriver = /* GraphQL */ `
  query GetDriver($id: ID!) {
    getDriver(id: $id) {
      id
      username
      name
      idNumber
      businessType
      age
      email
      phone
      city
      vehicleType
      lastDeliveryDate
      licensePhoto {
        bucket
        region
        key
        mimeType
      }
      smsEnabled
      agreedToTerms
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      deliveries {
        items {
          id
          username
          a_driver
          title
          senderName
          senderPhone
          recipientName
          recipientPhone
          date
          fromHour
          toHour
          pickupAddress
          deliveryAddress
          cost
          note
          driverId
          isUrgent
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listDrivers = /* GraphQL */ `
  query ListDrivers(
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        name
        idNumber
        businessType
        age
        email
        phone
        city
        vehicleType
        lastDeliveryDate
        licensePhoto {
          bucket
          region
          key
          mimeType
        }
        smsEnabled
        agreedToTerms
        isActive
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        deliveries {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const driversByUsername = /* GraphQL */ `
  query DriversByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driversByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        idNumber
        businessType
        age
        email
        phone
        city
        vehicleType
        lastDeliveryDate
        licensePhoto {
          bucket
          region
          key
          mimeType
        }
        smsEnabled
        agreedToTerms
        isActive
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        deliveries {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const driversByPhone = /* GraphQL */ `
  query DriversByPhone(
    $phone: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driversByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        idNumber
        businessType
        age
        email
        phone
        city
        vehicleType
        lastDeliveryDate
        licensePhoto {
          bucket
          region
          key
          mimeType
        }
        smsEnabled
        agreedToTerms
        isActive
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        deliveries {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDrivers = /* GraphQL */ `
  query SyncDrivers(
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDrivers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        name
        idNumber
        businessType
        age
        email
        phone
        city
        vehicleType
        lastDeliveryDate
        licensePhoto {
          bucket
          region
          key
          mimeType
        }
        smsEnabled
        agreedToTerms
        isActive
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        deliveries {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
