import moment from 'moment'
import config from '../aws-exports'
import { Storage } from 'aws-amplify'

export const mapToCognitoUsername = username => `+972${username.slice(1)}`

export const parseDateString = date => {
  const momentDate = moment(date).startOf('day')

  if (momentDate.isSame(moment().startOf('day'))) {
    return 'היום'
  } else if (momentDate.isSame(moment().add(1, 'days').startOf('day'))) {
    return 'מחר'
  } else {
    return moment(date).format('DD-MM-YYYY')
  }
}

export const isAdmin = () => {
  const b64Groups = sessionStorage.getItem(btoa(btoa('userGroups')))

  if (b64Groups) {
    const groups = atob(atob(b64Groups))
    return groups.includes('Admins')
  }

  return false
}

export const getLocalhostCognitoUsername = () =>
  localStorage.getItem(`CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}.LastAuthUser`)

export const getLocalhostCognitoPhoneNumber = () => {
  const phoneAndCountryCode = JSON.parse(localStorage
    .getItem(
      `CognitoIdentityServiceProvider.${
        config.aws_user_pools_web_client_id
      }.${getLocalhostCognitoUsername()}.userData`
    ))?.UserAttributes.find(att => att.Name === 'phone_number')?.Value

  return phoneAndCountryCode ? '0' + phoneAndCountryCode.slice(4) : ''
}

export const isSignedIn = () => !!getLocalhostCognitoUsername()

export const mapToDriverModel = driver => {
  const { _deleted, _lastChangedAt, updatedAt, createdAt, deliveries, ...restProps } = driver

  return restProps
}

export const createdAtSort = columns => (data, colIndex, order) => {
  const direction = order === 'desc' ? 1 : -1
  const isDateColumn = colIndex === columns.findIndex(column => column.name.includes('date'))

  return data.sort((a, b) => {
    if (isDateColumn) {
      const aDate = new Date(a.data[colIndex])
      const bDate = new Date(b.data[colIndex])
      return (aDate - bDate) * direction
    } else {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * direction
    }
  })
}

const vehicleTypes = {
  private: 'פרטי',
  motor: 'אופנוע/קטנוע',
  van: 'מסחרית',
}

export const mapToDriversDashboard = async drivers => {
  const urls = await Promise.all(
    drivers.map(driver => (driver.licensePhoto ? Storage.get(driver.licensePhoto.key) : null))
  )
  return drivers.map((driver, i) => ({
    ...driver,
    createdAt: moment(driver.createdAt).format('DD-MM-YYYY'),
    deliveries: driver.deliveries?.items.length,
    smsEnabled: driver.smsEnabled ? 'כן' : 'לא',
    licenseUrl: urls[i],
    vehicleType: vehicleTypes[driver.vehicleType],
  })).filter(d => !d._deleted)
}

export const generateWazeSearch = text => {
  const address = text.replace(' ', '%20')
  return `https://waze.com/ul?q=${address}`
}
