const REQUEST_SIGNUP = 'REQUEST_SIGNUP'
const SIGNUP_RECEIVED = 'SIGNUP_RECEIVED'
const RESIGN_IN_RECEIVED = 'RESIGN_IN_RECEIVED'
const REQUEST_SIGNOUT = 'REQUEST_SIGNOUT'
const TAKE_DELIVERY = 'TAKE_DELIVERY'
const ADD_DELIVERY = 'ADD_DELIVERY'
const ADD_DELIVERY_RECEIVED = 'ADD_DELIVERY_RECEIVED'
const REQUEST_SIGNIN = 'REQUEST_SIGNIN'
const SIGNIN_RECEIVED = 'SIGNIN_RECEIVED'
const ASYNC_REQUEST_FAILED = 'ASYNC_REQUEST_FAILED'
const FETCH_DRIVER = 'FETCH_DRIVER'
const DRIVER_RECEIVED = 'DRIVER_RECEIVED'
const FETCH_DELIVERIES = 'FETCH_DELIVERIES'
const DELIVERIES_RECEIVED = 'DELIVERIES_RECEIVED'
const SIGNOUT_RECEIVED = 'SIGNOUT_RECEIVED'
const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD'
const FORGOT_PASSWORD_RECEIVED = 'FORGOT_PASSWORD_RECEIVED'
const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
const RESET_PASSWORD_RECEIVED = 'RESET_PASSWORD_RECEIVED'
const SET_POST_AUTH_LOCATION = 'SET_POST_AUTH_LOCATION'
const UDPATE_DRIVER = 'UDPATE_DRIVER'
const SIGNUP_WITH_ERRORS = 'SIGNUP_WITH_ERRORS'
const CONFIRM_SIGNUP = 'CONFIRM_SIGNUP'
const CONFIRM_SIGNIN = 'CONFIRM_SIGNIN'
const CREATE_DRIVER = 'CREATE_DRIVER'
const CREATE_DRIVER_RECEIVED = 'CREATE_DRIVER_RECEIVED'
const UPDATE_DELIVERY = 'UPDATE_DELIVERY'
const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
const CONFIRM_SIGNIN_RECEIVED = 'CONFIRM_SIGNIN_RECEIVED'
const EDIT_DELIVERY = 'EDIT_DELIVERY'
const DELETE_DELIVERY = 'DELETE_DELIVERY'
const DELETE_DELIVERY_RECEIVED = 'DELETE_DELIVERY_RECEIVED'
const SET_SELECTED_DELIVERY = 'SET_SELECTED_DELIVERY'

const requestSignIn = payload => ({
  type: REQUEST_SIGNIN,
  payload
})

const signInReceived = payload => ({
  type: SIGNIN_RECEIVED,
  payload
})

const resignInReceived = payload => ({
  type: RESIGN_IN_RECEIVED,
  payload
})

const requestSignUp = payload => ({
  type: REQUEST_SIGNUP,
  payload
})

const signUpReceived = payload => ({
  type: SIGNUP_RECEIVED,
  payload
})

const signUpWithErrors = payload => ({
  type: SIGNUP_WITH_ERRORS,
  payload
})

const confirmSignUp = payload => ({
  type: CONFIRM_SIGNUP,
  payload
})

const confirmSignIn = payload => ({
  type: CONFIRM_SIGNIN,
  payload
})

const confirmSignInReceived = payload => ({
  type: CONFIRM_SIGNIN_RECEIVED,
  payload
})

const requestSignOut = payload => ({
  type: REQUEST_SIGNOUT,
  payload
})

const signOutReceived = payload => ({
  type: SIGNOUT_RECEIVED,
  payload
})

const fetchDriver = payload => ({
  type: FETCH_DRIVER,
  payload
})

const driverReceived = payload => ({
  type: DRIVER_RECEIVED,
  payload
})

const createDriverReceived = payload => ({
  type: CREATE_DRIVER_RECEIVED,
  payload
})

const takeDelivery = payload => ({
  type: TAKE_DELIVERY,
  payload
})

const addDelivery = payload => ({
  type: ADD_DELIVERY,
  payload
})

const fetchDeliveries = payload => ({
  type: FETCH_DELIVERIES,
  payload
})

const deliveriesReceived = payload => ({
  type: DELIVERIES_RECEIVED,
  payload
})


const addDeliveryReceived = payload => ({
  type: ADD_DELIVERY_RECEIVED,
  payload
})

const asyncRequestFailed = payload => ({
  type: ASYNC_REQUEST_FAILED,
  payload
})

const requestForgotPassword = payload => ({
  type: REQUEST_FORGOT_PASSWORD,
  payload
})

const forgotPasswordReceived = payload => ({
  type: FORGOT_PASSWORD_RECEIVED,
  payload
})

const requestResetPassword = payload => ({
  type: REQUEST_RESET_PASSWORD,
  payload
})

const resetPasswordReceived = payload => ({
  type: RESET_PASSWORD_RECEIVED,
  payload
})

const setPostAuthLocation = payload => ({
  type: SET_POST_AUTH_LOCATION,
  payload
})

const createDriver = payload => ({
  type: CREATE_DRIVER,
  payload
})

const updateDriver = payload => ({
  type: UDPATE_DRIVER,
  payload
})

const updateDelivery = payload => ({
  type: UPDATE_DELIVERY,
  payload
})

const hideSnackbar = payload => ({
  type: HIDE_SNACKBAR,
  payload
})

const showSnackbar = payload => ({
  type: SHOW_SNACKBAR,
  payload
})

const editDelivery = payload => ({
  type: EDIT_DELIVERY,
  payload
})

const deleteDelivery = payload => ({
  type: DELETE_DELIVERY,
  payload
})

const deleteDeliveryReceived = payload => ({
  type: DELETE_DELIVERY_RECEIVED,
  payload
})

const setSelectedDelivery = payload => ({
  type: SET_SELECTED_DELIVERY,
  payload
})

export default {
  requestSignUp,
  signUpReceived,
  takeDelivery,
  addDelivery,
  addDeliveryReceived,
  requestSignIn,
  signInReceived,
  asyncRequestFailed,
  fetchDeliveries,
  deliveriesReceived,
  fetchDriver,
  driverReceived,
  requestSignOut,
  signOutReceived,
  requestForgotPassword,
  forgotPasswordReceived,
  requestResetPassword,
  resetPasswordReceived,
  resignInReceived,
  setPostAuthLocation,
  createDriver,
  updateDriver,
  signUpWithErrors,
  confirmSignUp,
  confirmSignIn,
  createDriverReceived,
  updateDelivery,
  hideSnackbar,
  showSnackbar,
  confirmSignInReceived,
  editDelivery,
  deleteDelivery,
  deleteDeliveryReceived,
  setSelectedDelivery,
  REQUEST_SIGNUP,
  SIGNUP_RECEIVED,
  TAKE_DELIVERY,
  ADD_DELIVERY,
  ADD_DELIVERY_RECEIVED,
  REQUEST_SIGNIN,
  SIGNIN_RECEIVED,
  ASYNC_REQUEST_FAILED,
  FETCH_DELIVERIES,
  DELIVERIES_RECEIVED,
  FETCH_DRIVER,
  DRIVER_RECEIVED,
  REQUEST_SIGNOUT,
  SIGNOUT_RECEIVED,
  REQUEST_FORGOT_PASSWORD,
  FORGOT_PASSWORD_RECEIVED,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD_RECEIVED,
  RESIGN_IN_RECEIVED,
  SET_POST_AUTH_LOCATION,
  UDPATE_DRIVER,
  SIGNUP_WITH_ERRORS,
  CONFIRM_SIGNUP,
  CONFIRM_SIGNIN,
  CREATE_DRIVER,
  CREATE_DRIVER_RECEIVED,
  UPDATE_DELIVERY,
  HIDE_SNACKBAR,
  SHOW_SNACKBAR,
  CONFIRM_SIGNIN_RECEIVED,
  EDIT_DELIVERY,
  DELETE_DELIVERY,
  DELETE_DELIVERY_RECEIVED,
  SET_SELECTED_DELIVERY
}