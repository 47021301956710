import React from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../actions'
import { parseDateString } from '../utils/helpers'

const useStyles = makeStyles({
  paper: {
    minWidth: '80%',
    '@media (min-width: 1280px)': {
      minWidth: 400,
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    '& h2': {
      fontSize: '1.5rem !important',
      fontWeight: 'bold',
    },
  },
  section: {
    background: '#eee',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
  },
  dialogLabel: {
    marginBottom: 2,
    color: '#2c3e5090',
  },
  dialogValue: {
    marginBottom: 20,
  },
  primary: {
    background: '#2ecc71',
    color: 'white',
    fontSize: 17,
    flex: 1,
  },
  secondary: {
    background: '#e74c3c',
    fontSize: 17,
    color: 'white',
  },
})

const DeliveryItemExpanded = ({ delivery, handleClose }) => {
  const classes = useStyles()
  const isLoading = useSelector(state => state.appReducer.deliverySaving)
  const dispatch = useDispatch()

  return !!delivery ? (
    <Dialog open={!!delivery} onClose={handleClose} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>{delivery.title}</DialogTitle>
      <DialogContent>
        <div className={classes.dialogLabel}>מתי?</div>
        <div className={classes.dialogValue}>
          {parseDateString(delivery.date)}, {delivery.fromHour}-{delivery.toHour}
        </div>
        <div className={classes.section}>
          <div className={classes.dialogLabel}>כמה יוצא לי מזה?</div>
          <div>₪{delivery.cost}</div>
        </div>
        <div className={classes.dialogLabel}>מאיפה אוספים?</div>
        <div className={classes.dialogValue}>{delivery.pickupAddress}</div>
        <div className={classes.dialogLabel}>לאן שולחים?</div>
        <div className={classes.dialogValue}>{delivery.deliveryAddress}</div>
        <div className={classes.dialogLabel}>הערות כלשהן?</div>
        <div className={classes.dialogValue}>{delivery.note}</div>
        <DialogActions>
          {isLoading ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <Button
              variant="contained"
              className={classes.primary}
              onClick={() => dispatch(Actions.takeDelivery(delivery))}
            >
              קח את המשלוח
            </Button>
          )}
          <Button variant="contained" className={classes.secondary} onClick={handleClose}>
            ביטול
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  ) : null
}

export default DeliveryItemExpanded
