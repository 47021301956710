import React, { useState } from 'react'
import { makeStyles, Menu, MenuItem } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useHistory } from 'react-router'
import Logo from '../resources/logonew.png'
import { useDispatch } from 'react-redux'
import ShippingIcon from '@material-ui/icons/LocalShippingRounded'
import SignUpIcon from '@material-ui/icons/PostAddRounded'
import SignInIcon from '@material-ui/icons/AccountCircleRounded'
import NewDeliveryIcon from '@material-ui/icons/PlusOneRounded'
import SignOutIcon from '@material-ui/icons/ExitToAppRounded'
import SettingsIcon from '@material-ui/icons/SettingsRounded'
import DriversReport from '@material-ui/icons/DescriptionOutlined'
import DeliveriesHistory from '@material-ui/icons/HistoryRounded'
import TermsIcon from '@material-ui/icons/ListAltRounded'
import Actions from '../actions'
import { isAdmin, isSignedIn } from '../utils/helpers'

const useStyles = makeStyles({
  container: {
    height: 80,
    boxShadow: '0 0 10px 0 rgba(0,0,0,.4)',
    background: '#4bafaa',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px',
  },
  menu: {
    top: '80px !important',
    width: 290,
    left: 20,
    '& li, a': {
      paddingLeft: 'calc(300px - 74%)',
      height: 58,
      fontSize: 17,
      borderBottom: '1px solid #d3d3d330',
    },
  },
  menuIcon: {
    cursor: 'pointer',
  },
  itemLabel: {
    margin: '0 10px',
  },
})

const AppHeader = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const isAdminUser = isAdmin()
  const isSignedInUser = isSignedIn()

  const handleOpen = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(null)
  }

  return (
    <div className={classes.container}>
      <MenuIcon className={classes.menuIcon} onClick={handleOpen} />
      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.menu,
        }}
      >
        <MenuItem
          onClick={e => {
            handleClose(e)
            history.push('/')
          }}
        >
          <ShippingIcon />
          <span className={classes.itemLabel}>משלוחים</span>
        </MenuItem>
        {isAdminUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              history.push('/new-delivery')
            }}
          >
            <NewDeliveryIcon />
            <span className={classes.itemLabel}>הוסף משלוח</span>
          </MenuItem>
        )}
        {isSignedInUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              history.push('/deliveries-dashboard')
            }}
          >
            <DeliveriesHistory />
            <span className={classes.itemLabel}>היסטוריית משלוחים</span>
          </MenuItem>
        )}
        {isAdminUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              history.push('/drivers-dashboard')
            }}
          >
            <DriversReport />
            <span className={classes.itemLabel}>דוח נהגים</span>
          </MenuItem>
        )}
        {!isSignedInUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              history.push('/sign-in')
            }}
          >
            <SignInIcon />
            <span className={classes.itemLabel}>התחברות</span>
          </MenuItem>
        )}
        {!isSignedInUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              history.push('/sign-up')
            }}
          >
            <SignUpIcon />
            <span className={classes.itemLabel}>הרשמה</span>
          </MenuItem>
        )}
        {isSignedInUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              history.push('/settings')
            }}
          >
            <SettingsIcon />
            <span className={classes.itemLabel}>הגדרות משתמש</span>
          </MenuItem>
        )}

        <MenuItem component="a" href="https://tiktak-common.s3.amazonaws.com/terms.pdf" target="_blank">
          <TermsIcon />
          <span className={classes.itemLabel}>תקנון</span>
        </MenuItem>
        {isSignedInUser && (
          <MenuItem
            onClick={e => {
              handleClose(e)
              dispatch(Actions.requestSignOut())
            }}
          >
            <SignOutIcon />
            <span className={classes.itemLabel}>התנתקות</span>
          </MenuItem>
        )}
      </Menu>
      <div onClick={() => history.push('/')}>
        <img src={Logo} alt="logo" style={{ maxWidth: 140, cursor: 'pointer' }} />
      </div>
    </div>
  )
}

export default AppHeader
