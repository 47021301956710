/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDelivery = /* GraphQL */ `
  mutation CreateDelivery(
    $input: CreateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    createDelivery(input: $input, condition: $condition) {
      id
      username
      a_driver
      title
      senderName
      senderPhone
      recipientName
      recipientPhone
      date
      fromHour
      toHour
      pickupAddress
      deliveryAddress
      cost
      note
      driverId
      deliveryImage {
        bucket
        region
        key
        mimeType
      }
      isUrgent
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDelivery = /* GraphQL */ `
  mutation UpdateDelivery(
    $input: UpdateDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    updateDelivery(input: $input, condition: $condition) {
      id
      username
      a_driver
      title
      senderName
      senderPhone
      recipientName
      recipientPhone
      date
      fromHour
      toHour
      pickupAddress
      deliveryAddress
      cost
      note
      driverId
      deliveryImage {
        bucket
        region
        key
        mimeType
      }
      isUrgent
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDelivery = /* GraphQL */ `
  mutation DeleteDelivery(
    $input: DeleteDeliveryInput!
    $condition: ModelDeliveryConditionInput
  ) {
    deleteDelivery(input: $input, condition: $condition) {
      id
      username
      a_driver
      title
      senderName
      senderPhone
      recipientName
      recipientPhone
      date
      fromHour
      toHour
      pickupAddress
      deliveryAddress
      cost
      note
      driverId
      deliveryImage {
        bucket
        region
        key
        mimeType
      }
      isUrgent
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDriver = /* GraphQL */ `
  mutation CreateDriver(
    $input: CreateDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    createDriver(input: $input, condition: $condition) {
      id
      username
      name
      idNumber
      businessType
      age
      email
      phone
      city
      vehicleType
      lastDeliveryDate
      licensePhoto {
        bucket
        region
        key
        mimeType
      }
      smsEnabled
      agreedToTerms
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      deliveries {
        items {
          id
          username
          a_driver
          title
          senderName
          senderPhone
          recipientName
          recipientPhone
          date
          fromHour
          toHour
          pickupAddress
          deliveryAddress
          cost
          note
          driverId
          isUrgent
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateDriver = /* GraphQL */ `
  mutation UpdateDriver(
    $input: UpdateDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    updateDriver(input: $input, condition: $condition) {
      id
      username
      name
      idNumber
      businessType
      age
      email
      phone
      city
      vehicleType
      lastDeliveryDate
      licensePhoto {
        bucket
        region
        key
        mimeType
      }
      smsEnabled
      agreedToTerms
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      deliveries {
        items {
          id
          username
          a_driver
          title
          senderName
          senderPhone
          recipientName
          recipientPhone
          date
          fromHour
          toHour
          pickupAddress
          deliveryAddress
          cost
          note
          driverId
          isUrgent
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteDriver = /* GraphQL */ `
  mutation DeleteDriver(
    $input: DeleteDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    deleteDriver(input: $input, condition: $condition) {
      id
      username
      name
      idNumber
      businessType
      age
      email
      phone
      city
      vehicleType
      lastDeliveryDate
      licensePhoto {
        bucket
        region
        key
        mimeType
      }
      smsEnabled
      agreedToTerms
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      deliveries {
        items {
          id
          username
          a_driver
          title
          senderName
          senderPhone
          recipientName
          recipientPhone
          date
          fromHour
          toHour
          pickupAddress
          deliveryAddress
          cost
          note
          driverId
          isUrgent
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
