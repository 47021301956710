import 'date-fns'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import CalendarIcon from '@material-ui/icons/Today'

const CustomDatePicker = ({
  value,
  label = '',
  maxDate = new Date('2100-01-01'),
  handleDateChange,
  disabled = false,
  disablePast = true,
  style = {},
  minDateMessage = 'Date should not be before minimal date',
  maxDateMessage = 'Date should not be after maximal date'
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        justify="space-around"
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          ...style
        }}
      >
       <CalendarIcon
          style={{
            color: 'grey',
            cursor: 'auto',
            zIndex: 1,
            position: 'absolute',
            left: 0,
            top: 20
          }}
        />
        <DatePicker
          disabled={disabled}
          style={{ cursor: 'pointer' }}
          disablePast={disablePast}
          margin="normal"
          label={label}
          format="dd/MM/yyyy"
          value={value}
          maxDate={maxDate}
          placeholder="Set a date"
          onChange={handleDateChange}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default CustomDatePicker
