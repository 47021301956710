import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { makeStyles, Modal, Fade, Backdrop } from '@material-ui/core'
// import * as ROUTES from '../consts/routerConsts'
import { createdAtSort, mapToDriversDashboard } from '../utils/helpers'
import { API, graphqlOperation } from 'aws-amplify'
import { listDrivers } from '../graphql/customQueries'
import AppLoader from './AppLoader'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
  container: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiPaper-root': {
      width: '100%',
      boxShadow: '0px 0px 1px gray',
    },
    '& .MuiTableCell-head, .MUIDataTableHeadCell': {
      background: '#eee',
      fontWeight: 'bold',
    },
  },
  link: {
    margin: 20,
  },
  back: {
    margin: 20,
    padding: 10,
    border: '1px solid lightgray',
    borderRadius: 30,
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'background-color 0.25s ease',
    '&:hover': {
      backgroundColor: '#efefef',
    },
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div': {
      outline: 'none',
    },
    '& img': {
      outline: 'none',
      maxWidth: '80%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgLink: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

const DriversDashboard = () => {
  const classes = useStyles()
  const [drivers, setDrivers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [imgPreview, setImgPreview] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    API.graphql(graphqlOperation(listDrivers, { limit: 10000 }))
      .then(({ data }) => data.listDrivers.items || [])
      .then(mapToDriversDashboard)
      .then(setDrivers)
      .catch(console.log)
      .finally(() => setIsLoading(false))
  }, [])

  const columns = [
    { name: 'name', label: 'שם הנהג' },
    { name: 'phone', label: 'טלפון' },
    { name: 'vehicleType', label: 'סוג הרכב' },
    { name: 'smsEnabled', label: 'מנוי לשירות SMS' },
    { name: 'deliveries', label: 'כמות משלוחים' },
    {
      name: 'licenseUrl',
      label: 'רישיון נהיגה',
      options: {
        textLabels: {
          body: {
            noMatch: 'לא נמצאו נתונים'
          }
        },
        customBodyRender: value =>
          value ? (
            <span className={classes.imgLink} onClick={() => setImgPreview(value)}>
              צילום רישיון
            </span>
          ) : (
            <span>-</span>
          ),
      },
    },
    { name: 'createdAt', label: 'מתי נרשם למערכת' },
  ]

  const options = {
    filterType: 'search',
    downloadOptions: {
      filename: 'Drivers.csv',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    print: false,
    selectableRows: 'single',
    customSort: createdAtSort(columns),
  }

  return isLoading ? (
    <AppLoader open={isLoading} />
  ) : (
    <div className={classes.container}>
      <Modal
        className={classes.imgPreviewContainer}
        open={!!imgPreview}
        onClose={() => setImgPreview(null)}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={!!imgPreview}>
          <div className={classes.content}>
            <div className={classes.closeBtn} onClick={() => setImgPreview(null)}>
              <CloseIcon
                width={40}
                height={40}
                style={{ background: 'white', padding: 9, borderRadius: 20, boxShadow: '0 0 6px black' }}
              />
            </div>
            <img src={imgPreview} alt="צילום רישיון" style={{ maxWidth: '80vw', maxHeight: '80vh' }} />
          </div>
        </Fade>
      </Modal>
      <MUIDataTable title={'דוח נהגים'} data={drivers} columns={columns} options={options} />
    </div>
  )
}

export default DriversDashboard
