import Actions from '../actions'

const initialState = {
  deliveries: [],
  driver: {},
  selectedDelivery: null,
  authLoading: false,
  settingsLoading: false,
  postAuthPath: '',
  needsConfirmation: false,
  deliveriesLoading: false,
  deliverySaving: false,
  deliveryOnEdit: null,
  snackbarOpen: false,
  snackbarText: '',
  snackbarType: 'info',
  snackbarDuration: 3000,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.REQUEST_SIGNIN:
      return {
        ...state,
        authLoading: true,
        signInError: '',
      }
    case Actions.REQUEST_SIGNUP:
      return {
        ...state,
        authLoading: true,
      }
    case Actions.SIGNUP_RECEIVED:
      return {
        ...state,
        authLoading: false,
        needsConfirmation: !!action.payload.success,
      }
    case Actions.CONFIRM_SIGNUP:
      return {
        ...state,
        authLoading: true,
      }
    case Actions.CONFIRM_SIGNIN_RECEIVED:
      return {
        ...state,
        authLoading: false,
      }
    case Actions.SIGNIN_RECEIVED:
      return {
        ...state,
        authLoading: false,
      }
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.FETCH_DELIVERIES:
      return {
        ...state,
        deliveriesLoading: true,
        deliverySaving: false,
      }
    case Actions.UPDATE_DELIVERY:
      return {
        ...state,
        deliverySaving: true,
        deliveryOnEdit: null,
      }
    case Actions.REQUEST_RESET_PASSWORD:
      return {
        ...state,
        authLoading: true,
      }
    case Actions.RESET_PASSWORD_RECEIVED:
      return {
        ...state,
        authLoading: false,
        snackbarOpen: true,
        snackbarText: 'הסיסמא שונתה בהצלחה, חזור למסך התחברות',
        snackbarType: 'success',
        snackbarDuration: 3000,
      }
    case Actions.DELIVERIES_RECEIVED:
      return {
        ...state,
        deliveries: action.payload,
        deliveriesLoading: false,
      }
    case Actions.FETCH_DRIVER:
    return {
      ...state,
      deliveriesLoading: true
    }
    case Actions.DRIVER_RECEIVED:
      return {
        ...state,
        driver: action.payload,
        deliveriesLoading: false,
        settingsLoading: false,
      }
    case Actions.ADD_DELIVERY:
      return {
        ...state,
        deliverySaving: true,
      }
    case Actions.ADD_DELIVERY_RECEIVED:
      return {
        ...state,
        deliverySaving: false,
        snackbarOpen: true,
        snackbarText: 'המשלוח נשמר בהצלחה',
        snackbarType: 'success',
        snackbarDuration: 3000,
      }
    case Actions.SET_POST_AUTH_LOCATION:
      return {
        ...state,
        postAuthPath: action.payload,
      }
    case Actions.CREATE_DRIVER:
    case Actions.UDPATE_DRIVER:
      return {
        ...state,
        settingsLoading: true,
      }
    case Actions.CREATE_DRIVER_RECEIVED:
      return {
        ...state,
        driver: action.payload,
      }
    case Actions.TAKE_DELIVERY:
    case Actions.FINISH_DELIVERY:
      return {
        ...state,
        deliverySaving: true,
      }
    case Actions.SET_SELECTED_DELIVERY: {
      const newSelectedDelivery = action.payload?.id
        ? { ...state.deliveries.find(d => d.id === action.payload.id), ...action.payload }
        : null
      const newDeliveries = action.payload?.id
        ? state.deliveries.filter(d => d.id !== action.payload.id).concat(newSelectedDelivery)
        : state.deliveries
      return {
        ...state,
        selectedDelivery: newSelectedDelivery,
        deliveries: newDeliveries,
        deliverySaving: false,
      }
    }
    case Actions.HIDE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      }
    case Actions.SHOW_SNACKBAR:
      return {
        ...state,
        ...action.payload,
      }
    case Actions.EDIT_DELIVERY:
      return {
        ...state,
        deliveryOnEdit: action.payload,
      }
    case Actions.DELETE_DELIVERY:
      return {
        ...state,
        deliveriesLoading: true,
      }
    case Actions.DELETE_DELIVERY_RECEIVED: {
      const { deliveryId, ...snackbarProps } = action.payload
      return {
        ...state,
        deliveriesLoading: false,
        deliveries: state.deliveries.filter(d => d.id !== deliveryId),
        ...snackbarProps,
      }
    }
    case Actions.ASYNC_REQUEST_FAILED:
      console.log(action.payload)
      return {
        ...state,
        settingsLoading: false,
        authLoading: false,
        deliveriesLoading: false,
        ...action.payload,
      }

    default:
      return state
  }
}
