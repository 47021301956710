import React, { useState } from 'react'
import { makeStyles, TextField, Button, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../actions'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
  },
  signUp: {
    width: 500,
    marginTop: 30,
    borderRadius: 5,
    padding: 20,
    '@media (min-width: 1280px)': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,.7)',
    },
  },
  input: {
    width: '100%',
    height: 55,
  },
  confirmBtn: {
    marginTop: 20,
    width: '100%',
    height: 55,
    background: '#3498db',
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
})

const SignUp = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.appReducer.authLoading)
  const needsConfirmation = useSelector(state => state.appReducer.needsConfirmation)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')

  const handleFormSubmit = e => {
    e.preventDefault()
    dispatch(
      needsConfirmation
        ? Actions.confirmSignUp({ username, password, code })
        : Actions.requestSignUp({ username, password, email: 't@g.com' })
    )
  }

  return (
    <div className={classes.container}>
      <form className={classes.signUp} onSubmit={handleFormSubmit}>
        <h2>הרשמה</h2>
        <TextField
          classes={{ root: classes.input }}
          label="מספר טלפון"
          variant="outlined"
          onChange={({ target }) => setUsername(target.value)}
        />
        <br />
        <br />
        <TextField
          classes={{ root: classes.input }}
          label="צור סיסמא (באורך של לפחות 8 תווים)"
          variant="outlined"
          type="password"
          onChange={({ target }) => setPassword(target.value)}
        />
        <br />
        <br />
        {needsConfirmation && (
          <>
            <TextField
              classes={{ root: classes.input }}
              label="קוד אימות"
              variant="outlined"
              type="number"
              onChange={({ target }) => setCode(target.value)}
            />
            <br />
            <br />
          </>
        )}

        <div>
          {isLoading ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <Button type="submit" variant="contained" classes={{ root: classes.confirmBtn }}>
              {needsConfirmation ? 'אימות הרשמה' : 'הרשמה'}
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default SignUp
