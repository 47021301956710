import React, { useState } from 'react'
import {
  makeStyles,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import { Label } from 'aws-amplify-react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../actions'
import { Storage } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import config from '../aws-exports'
import ImageUpload from './ImageUploader'
import { getLocalhostCognitoPhoneNumber } from '../utils/helpers'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  signUp: {
    width: 500,
    marginTop: 30,
    borderRadius: 5,
    padding: 20,
    '@media (min-width: 1280px)': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,.7)',
    },
  },
  input: {
    width: '100%',
    height: 55,
    '& .Mui-disabled': {
      background: '#eee',
    },
  },
  confirmBtn: {
    marginTop: 20,
    width: '100%',
    height: 55,
    background: '#3498db',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  photoUploadLabel: {
    background: '#eee',
    color: 'gray',
    padding: '30px 100px',
    borderRadius: 5,
    border: '1px dashed gray',
    cursor: 'pointer',
  },
})

const DriverInfo = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.appReducer.settingsLoading)
  const [isUploading, setIsUploading] = useState(false)
  const [license, setLicense] = useState(null)
  const [newDriver, setNewDriver] = useState({
    businessType: 'allowed',
    vehicleType: 'private',
    smsEnabled: false,
    phone: getLocalhostCognitoPhoneNumber(),
  })

  const handleFormSubmit = e => {
    e.preventDefault()
    const uuid = uuidv4()
    setIsUploading(true)
    Storage.put(uuid, license).then(() => {
      setIsUploading(false)
      dispatch(
        Actions.createDriver({
          ...newDriver,
          licensePhoto: {
            bucket: config.aws_user_files_s3_bucket,
            region: config.aws_user_files_s3_bucket_region,
            key: uuid,
            mimeType: 'image',
          },
        })
      )
    }).finally(() => setIsUploading(false))
  }

  return (
    <div className={classes.container}>
      <form className={classes.signUp} onSubmit={handleFormSubmit}>
        <h2>מילוי פרטים אישיים</h2>
        <TextField
          classes={{ root: classes.input }}
          label="שם מלא"
          required
          variant="outlined"
          onChange={({ target }) => setNewDriver({ ...newDriver, name: target.value })}
        />
        <br />
        <br />
        <Label>סוג העסק</Label>
        <RadioGroup
          row
          value={newDriver.businessType}
          onChange={({ target }) => setNewDriver({ ...newDriver, businessType: target.value })}
        >
          <FormControlLabel
            value="free"
            control={<Radio checked={newDriver.businessType === 'free'} />}
            label="עוסק פטור"
          />
          <FormControlLabel
            value="allowed"
            control={<Radio checked={newDriver.businessType === 'allowed'} />}
            label="עוסק מורשה"
          />
        </RadioGroup>
        <br />
        <TextField
          required
          classes={{ root: classes.input }}
          label={newDriver.businessType === 'none' ? 'מספר ת.ז' : 'ח"פ'}
          variant="outlined"
          type="number"
          onChange={({ target }) => setNewDriver({ ...newDriver, idNumber: target.value })}
        />
        <br />
        <br />
        <TextField
          required
          classes={{ root: classes.input }}
          label="גיל"
          type="number"
          variant="outlined"
          onChange={({ target }) => setNewDriver({ ...newDriver, age: target.value })}
        />
        <br />
        <br />
        <TextField
          required
          classes={{ root: classes.input }}
          label="מספר טלפון"
          type="number"
          variant="outlined"
          disabled
          value={newDriver.phone}
          // onChange={({ target }) => setNewDriver({ ...newDriver, phone: target.value })}
        />
        <br />
        <br />
        <TextField
          required
          classes={{ root: classes.input }}
          label="עיר מגורים"
          variant="outlined"
          onChange={({ target }) => setNewDriver({ ...newDriver, city: target.value })}
        />
        <br />
        <br />
        <Label>סוג הרכב</Label>
        <RadioGroup
          row
          value={newDriver.vehicleType}
          onChange={({ target }) => setNewDriver({ ...newDriver, vehicleType: target.value })}
        >
          <FormControlLabel
            value="private"
            control={<Radio checked={newDriver.vehicleType === 'private'} />}
            label="פרטי"
          />
          <FormControlLabel
            value="motor"
            control={<Radio checked={newDriver.vehicleType === 'motor'} />}
            label="אופנוע/קטנוע"
          />
          <FormControlLabel
            value="van"
            control={<Radio checked={newDriver.vehicleType === 'van'} />}
            label="מסחרית"
          />
        </RadioGroup>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              value={newDriver.smsEnabled}
              onChange={({ target }) => setNewDriver({ ...newDriver, smsEnabled: target.checked })}
              name="smsEnabled"
            />
          }
          label="אני מעוניין/ת לקבל הודעות SMS על משלוחים חדשים"
        />
        <br />
        <Checkbox
          style={{ paddingRight: 0 }}
          value={newDriver.agreedToTerms}
          onChange={({ target }) => setNewDriver({ ...newDriver, agreedToTerms: target.checked })}
          name="terms"
        />

        <span style={{ fontWeight: 'bold'}}>
          קראתי את{' '}
          <a href="https://tiktak-common.s3.amazonaws.com/terms.pdf" target="_blank" rel="noopener noreferrer">
            התקנון
          </a>{' '}
          ואני מקבל את תנאי השימוש
        </span>
        <br />
        <br />
        <Label>צילום רישיון</Label>
        <ImageUpload handleImageUpload={image => setLicense(image)} />
        <br />
        <br />

        {isLoading || isUploading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Button
            type="submit"
            variant="contained"
            classes={{ root: classes.confirmBtn }}
            disabled={!newDriver.agreedToTerms || !license}
          >
            שמור פרטים
          </Button>
        )}
      </form>
    </div>
  )
}

export default DriverInfo
