import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Backdrop } from '@material-ui/core'

const useLoaderStyles = makeStyles({
  loader: {
    zIndex: 9999,
    backgroundColor: '#f5f5f590',
    color: '#f50057'
  },
})

const AppLoader = ({ open }) => {
  const classes = useLoaderStyles()

  return (
    <Backdrop className={classes.loader} open={open}>
      <CircularProgress color="inherit" size={60} />
    </Backdrop>
  )
}

export default AppLoader
