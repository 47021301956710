import Actions from '../actions'
import { ofType } from 'redux-observable'
import {
  mergeMap,
  pluck,
  switchMap,
  catchError,
  map,
  withLatestFrom,
  mapTo,
  filter,
} from 'rxjs/operators'
import { from, of, concat } from 'rxjs'
import { API, graphqlOperation } from 'aws-amplify'
import {
  createDelivery,
  updateDelivery,
  updateDriver,
  createDriver,
  deleteDelivery,
} from '../graphql/mutations'
import { listDeliverys, driversByUsername } from '../graphql/queries'
import { mapToDriverModel, getLocalhostCognitoUsername, isSignedIn } from '../utils/helpers'
import * as ROUTES from '../consts/routerConsts'
import { push } from 'connected-react-router'

export const addDeliveryEpic = action$ =>
  action$.pipe(
    ofType(Actions.ADD_DELIVERY),
    pluck('payload'),
    switchMap(delivery =>
      from(
        API.graphql(
          graphqlOperation(createDelivery, {
            input: { ...delivery, username: localStorage.getItem('driverUsername'), status: 'new' },
          })
        )
      ).pipe(
        map(({ data }) => data.createDelivery),
        mergeMap(newDelivery => of(Actions.addDeliveryReceived(newDelivery))),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const fetchDeliveriesEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_DELIVERIES),
    switchMap(() =>
      from(
        API.graphql({ ...graphqlOperation(listDeliverys, { limit: 100000 }), authMode: 'API_KEY' })
      ).pipe(
        map(({ data }) => data.listDeliverys.items.filter(d => !d._deleted)),
        mergeMap(data => of(Actions.deliveriesReceived(data))),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const takeDeliveryEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.TAKE_DELIVERY),
    withLatestFrom(state$),
    map(([action, state]) => ({
      delivery: action.payload,
      driver: state.appReducer.driver,
    })),
    switchMap(({ delivery, driver }) =>
      from(
        API.put('generalRest', '/delivery', {
          body: {
            id: delivery.id,
            _version: delivery._version,
            driverId: driver.id,
            a_driver: driver.username,
            status: 'pickup',
          },
        })
      ).pipe(
        mergeMap(({ error }) =>
          error === 'This delivery is already assigned to a driver'
            ? concat(
                of(
                  Actions.asyncRequestFailed({
                    selectedDelivery: null,
                    snackbarOpen: true,
                    snackbarText: 'המשלוח כבר נתפס על ידי מישהו אחר',
                    snackbarType: 'error',
                    snackbarDuration: 3000,
                  })
                ),
                of(push(ROUTES.DELIVERIES)),
                of(Actions.fetchDeliveries())
              )
            : of(
                Actions.setSelectedDelivery({
                  ...delivery,
                  _version: delivery._version + 1,
                  driverId: driver.id,
                  a_driver: driver.username,
                  status: 'pickup',
                })
              )
        ),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const fetchDriverEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_DRIVER),
    pluck('payload'),
    switchMap(username =>
      from(API.graphql(graphqlOperation(driversByUsername, { username }))).pipe(
        map(({ data }) => data.driversByUsername.items[0]),
        mergeMap(data => of(Actions.driverReceived(data))),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const signInReceivedFetchDriverEpic = action$ =>
  action$.pipe(
    ofType(Actions.SIGNIN_RECEIVED),
    pluck('payload', 'username'),
    map(username => Actions.fetchDriver(username))
  )

export const createDriverEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_DRIVER),
    pluck('payload'),
    switchMap(driver =>
      from(
        API.graphql(
          graphqlOperation(createDriver, {
            input: { ...driver, username: getLocalhostCognitoUsername() },
          })
        )
      ).pipe(
        map(({ data }) => data.createDriver),
        mergeMap(data => of(Actions.createDriverReceived(data))),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const createDriverReceivedNavigationEpic = action$ =>
  action$.pipe(ofType(Actions.CREATE_DRIVER_RECEIVED), mapTo(push(ROUTES.DELIVERIES)))

export const updateDriverEpic = action$ =>
  action$.pipe(
    ofType(Actions.UDPATE_DRIVER),
    pluck('payload'),
    map(mapToDriverModel),
    switchMap(driver =>
      from(API.graphql(graphqlOperation(updateDriver, { input: driver }))).pipe(
        map(({ data }) => data.updateDriver),
        mergeMap(data =>
          concat(
            of(Actions.driverReceived(data)),
            of(
              Actions.showSnackbar({
                snackbarOpen: true,
                snackbarText: 'הפרטים נשמרו בהצלחה',
                snackbarType: 'success',
                snackbarDuration: 3000,
              })
            )
          )
        ),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const updateDeliveryEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_DELIVERY),
    pluck('payload'),
    map(delivery => {
      const { _lastChangedAt, _deleted, updatedAt, createdAt, reload, ...restProps } = delivery
      return { delivery: restProps, reload }
    }),
    switchMap(({ delivery, reload }) =>
      from(
        API.graphql(
          graphqlOperation(updateDelivery, {
            input: { id: delivery.id, _version: delivery._version, ...delivery },
          })
        )
      ).pipe(
        mergeMap(() =>
          reload
            ? concat(of(push(ROUTES.DELIVERIES)), of(Actions.fetchDeliveries()))
            : of(Actions.setSelectedDelivery({ ...delivery, _version: delivery._version + 1 }))
        ),
        catchError(err => of(Actions.asyncRequestFailed({ err })))
      )
    )
  )

export const fetchDriverReceived = action$ =>
  action$.pipe(
    ofType(Actions.DRIVER_RECEIVED),
    pluck('payload'),
    filter(driver => !driver),
    mergeMap(() =>
      concat(
        of(push(isSignedIn() ? ROUTES.DRIVER_INFO : ROUTES.SIGN_IN)),
        of(
          Actions.asyncRequestFailed({
            snackbarOpen: true,
            snackbarText: isSignedIn()
              ? 'ההרשמה טרם הסתיימה, יש למלא פרטים אישיים'
              : 'ההרשמה טרם הסתיימה, נא להתחבר ולמלא פרטים אישיים',
            snackbarType: 'error',
            snackbarDuration: 3000,
          })
        )
      )
    )
  )

export const editDeliveryEpic = action$ =>
  action$.pipe(ofType(Actions.EDIT_DELIVERY), mapTo(push(ROUTES.NEW_DELIVERY)))

export const deleteDeliveryEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_DELIVERY),
    pluck('payload'),
    switchMap(delivery =>
      from(
        API.graphql(
          graphqlOperation(deleteDelivery, {
            input: { id: delivery.id, _version: delivery._version },
          })
        )
      ).pipe(
        mergeMap(res =>
          of(
            Actions.deleteDeliveryReceived({
              deliveryId: delivery.id,
              snackbarOpen: true,
              snackbarText: 'המשלוח נמחק בהצלחה',
              snackbarType: 'success',
              snackbarDuration: 3000,
            })
          )
        ),
        catchError(err =>
          of(
            Actions.asyncRequestFailed({
              err,
              snackbarOpen: true,
              snackbarText: 'התרחשה שגיאה במחיקת המשלוח',
              snackbarType: 'error',
              snackbarDuration: 3000,
            })
          )
        )
      )
    )
  )
