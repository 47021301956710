import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, TextField, Button, CircularProgress } from '@material-ui/core'
import Actions from '../actions'
import * as ROUTES from '../consts/routerConsts'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  signIn: {
    width: 500,
    marginTop: 30,
    borderRadius: 5,
    padding: 20,
    '@media (min-width: 1280px)': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,.7)',
    },
  },
  input: {
    width: '100%',
    height: 55,
  },
  confirmBtn: {
    width: '100%',
    height: 55,
    background: '#3498db',
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
  forgot: {
    marginTop: 20,
    color: '#3498db',
    cursor: 'pointer',
  },
})

const SignIn = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(state => state.appReducer.authLoading)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleFormSubmit = e => {
    e.preventDefault()
    dispatch(Actions.requestSignIn({ username, password }))
  }

  return (
    <form className={classes.container} onSubmit={handleFormSubmit}>
      <div className={classes.signIn}>
        <h2>התחבר/י לחשבונך</h2>
        <TextField
          classes={{ root: classes.input }}
          label="מספר טלפון"
          variant="outlined"
          type="number"
          required
          value={username}
          onChange={({ target }) => setUsername(target.value)}
        />
        <br />
        <br />
        <TextField
          classes={{ root: classes.input }}
          label="סיסמא"
          variant="outlined"
          required
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
        <div
          className={classes.forgot}
          onClick={() =>
            username
              ? dispatch(Actions.requestForgotPassword(username))
              : dispatch(
                  Actions.asyncRequestFailed({
                    snackbarOpen: true,
                    snackbarText: 'יש להזין מספר טלפון',
                    snackbarType: 'error',
                    snackbarDuration: 3000,
                  })
                )
          }
        >
          שכחתי את הסיסמא
        </div>
        <br />
        {isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button type="submit" variant="contained" classes={{ root: classes.confirmBtn }}>
              התחבר
            </Button>
            <span style={{ marginTop: 10 }}>- או -</span>
            <div className={classes.forgot} onClick={() => history.push(ROUTES.SIGN_UP)}>
              לחץ כאן כדי ליצור חשבון חדש
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default SignIn
