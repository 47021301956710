import React from 'react'
import ImageUploader from 'react-images-upload'
import { makeStyles } from '@material-ui/core'
import imageCompression from 'browser-image-compression';

const useStyles = makeStyles({
  container: {
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
    borderRadius: 5,
    '& uploadPictureContainer': {
      width: '50% !important'
    }
  }
})

const compressImage = async imageFile => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
 
    return compressedFile
  } catch (error) {
    console.log(error);
  }
 
}

const ImageUpload = ({ handleImageUpload }) => {
  const classes = useStyles()

  return (
    <ImageUploader
      className={classes.container}
      buttonText="בחר תמונה להעלאה"
      onChange={(files) => {
        compressImage(files[0]).then(compressImage => handleImageUpload(compressImage))
      }}
      imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
      fileSizeError="הקובץ גדול מדי, נא להעלות קובץ עד 5MB"
      fileTypeError="אין תמיכה בקבצים מסוג זה"
      withIcon
      maxFileSize={5242880}
      withPreview
      singleImage
    />
  )
}

export default ImageUpload