import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  makeStyles,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from '@material-ui/core'
import Actions from '../actions'
import { useHistory } from 'react-router'
import * as ROUTES from '../consts/routerConsts'
import { v4 as uuidv4 } from 'uuid'
import config from '../aws-exports'
import ImageUpload from './ImageUploader'
import { Storage } from 'aws-amplify'
import { isSignedIn, getLocalhostCognitoUsername } from '../utils/helpers'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 30
  },
  settings: {
    width: 500,
    marginTop: 30,
    padding: 20,
    '@media (min-width: 1280px)': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,.7)',
    },
  },
  input: {
    width: '90%',
    borderRadius: 50,
    height: 55,
    '& .Mui-disabled': {
      background: '#eee',
    },
  },
  confirmBtn: {
    width: '55%',
    height: 55,
    background: '#3498db',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  backBtn: {
    border: '1px solid #3498db',
    width: '40%',
    fontSize: 16,
    height: 55,
    color: '#3498db',
  },
  label: {
    marginBottom: 2,
    color: '#2c3e5090',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  photoUploadLabel: {
    background: '#eee',
    color: 'gray',
    padding: '30px 100px',
    borderRadius: 5,
    border: '1px dashed gray',
    cursor: 'pointer',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '70%'
    }
  },
  replacePhoto: {
    color: '#3498db',
    background: '#eee',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    cursor: 'pointer'
  }
})

const Settings = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [license, setLicense] = useState(null)
  const [licenseUrl, setLicenseUrl] = useState(null)
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false)
  const isLoading = useSelector(state => state.appReducer.settingsLoading)
  const driver = useSelector(state => state.appReducer.driver)
  const [driverUpdate, setDriverUpdate] = useState({ ...(driver || {}) })

  useEffect(() => {
    if (isSignedIn() && !driver?.id) {
      dispatch(Actions.fetchDriver(getLocalhostCognitoUsername()))
    }
  }, [dispatch, driver])

  useEffect(() => {
    if (driver?.licensePhoto) {
      Storage.get(driver?.licensePhoto.key).then(url => {
        setLicenseUrl(url)
      })
    }

    setDriverUpdate({ ...(driver || {}) })
  }, [driver])

  const handleFormSubmit = e => {
    e.preventDefault()
    const uuid = uuidv4()
    setIsUploadingPhoto(true)
    Storage.put(uuid, license)
      .then(() => {
        dispatch(
          Actions.updateDriver(
            license
              ? {
                  ...driverUpdate,
                  licensePhoto: {
                    bucket: config.aws_user_files_s3_bucket,
                    region: config.aws_user_files_s3_bucket_region,
                    key: uuid,
                    mimeType: 'image',
                  },
                }
              : driverUpdate
          )
        )
      })
      .finally(() => setIsUploadingPhoto(false))
  }

  return driverUpdate.id ? (
    <form className={classes.container} onSubmit={handleFormSubmit}>
      <div className={classes.settings}>
        <h2>הגדרות משתמש - {driverUpdate.name}</h2>

        <div className={classes.row}>
          <div>
            <div className={classes.label}>מספר טלפון</div>
            <TextField
              disabled
              classes={{ root: classes.input }}
              variant="outlined"
              value={driverUpdate.phone}
              onChange={({ target }) => setDriverUpdate({ ...driverUpdate, phone: target.value })}
            />
          </div>

          <div>
            <div className={classes.label}>עיר מגורים</div>
            <TextField
              classes={{ root: classes.input }}
              variant="outlined"
              value={driverUpdate.city || ''}
              onChange={({ target }) => setDriverUpdate({ ...driverUpdate, city: target.value })}
            />
          </div>
        </div>

        <br />

        <div className={classes.label}>סוג רכב</div>
        <div className={classes.value}>
          <RadioGroup
            row
            value={driverUpdate.vehicleType || 'private'}
            onChange={({ target }) =>
              setDriverUpdate({ ...driverUpdate, vehicleType: target.value })
            }
          >
            <FormControlLabel
              value="private"
              control={<Radio checked={driverUpdate.vehicleType === 'private'} />}
              label="פרטי"
            />
            <FormControlLabel
              value="motor"
              control={<Radio checked={driverUpdate.vehicleType === 'motor'} />}
              label="אופנוע/קטנוע"
            />
            <FormControlLabel
              value="van"
              control={<Radio checked={driverUpdate.vehicleType === 'van'} />}
              label="מסחרית"
            />
          </RadioGroup>
        </div>

        <br />

        <div className={classes.label}>מנוי לשירות SMS</div>
        <div className={classes.value}>
          <Checkbox
            checked={driverUpdate.smsEnabled}
            onChange={({ target }) =>
              setDriverUpdate({ ...driverUpdate, smsEnabled: target.checked })
            }
          />
          <span>אני מעוניין/ת לקבל הודעות SMS על משלוחים חדשים</span>
        </div>

        <br />
        <div className={classes.label}>צילום רישיון נהיגה</div>
        {licenseUrl ? (
          <div className={classes.imgContainer}>
            <div className={classes.replacePhoto} onClick={() => setLicenseUrl(null)}>החלף תמונת רישיון</div>
            <img src={licenseUrl} alt="צילון רישיון" />
          </div>
        ) : (
          <ImageUpload handleImageUpload={image => setLicense(image)} />
        )}
        <br />
        {isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            {!isLoading && !isUploadingPhoto ? (
              <Button type="submit" className={classes.confirmBtn}>
                שמור שינויים
              </Button>
            ) : (
              <CircularProgress color="secondary" />
            )}
            <Button className={classes.backBtn} onClick={() => history.push(ROUTES.DELIVERIES)}>
              חזרה למשלוחים
            </Button>
          </div>
        )}
      </div>
    </form>
  ) : null
}

export default Settings
