import React, { useState } from 'react'
import { makeStyles, Button, Grow, IconButton } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { parseDateString, isSignedIn, isAdmin } from '../utils/helpers'
import DeliveryItemExpanded from './DeliveryItemExpanded'
import UnRegisteredDialog from './UnRegisteredDialog'
import OngoingDeliveryInfo from './OngoingDeliveryInfo'
import Actions from '../actions'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Confirmation from './Confirmation'

const useStyles = ({ deliveryStatus }) =>
  makeStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: '#ecf0f1',
      borderRadius: 10,
      padding: 20,
      marginBottom: 40,
      boxShadow:
        deliveryStatus === 'picked' || deliveryStatus === 'pickup' ? '0 0 18px #15847999' : 'none',
    },
    title: {
      width: '68%',
      fontSize: 24,
      fontWeight: 'bold',
      color: '#34495e',
      marginBottom: 11,
    },
    button: {
      background: '#3498db',
      fontSize: 16,
      color: 'white',
      width: '100%',
    },
    label: {
      marginBottom: 5,
    },
    navigate: {
      marginTop: 10,
      background: '#3498db',
      color: 'white',
    },
    instructions: {
      position: 'absolute',
      top: 10,
      right: 10,
      background: 'red',
      height: '100%',
    },
    smallBtn: {
      width: 50,
      background: 'lightgray',
    },
    smallBtnRed: {
      width: 50,
      background: '#e74c3c',
      color: 'white',
    },
    ribbon: {
      fontSize: 17,
      width: 150,
      height: 150,
      overflow: 'hidden',
      position: 'absolute',
      '&::before': {
        position: 'absolute',
        zIndex: -1,
        content: "''",
        display: 'block',
        border: deliveryStatus === 'taken' ? '5px solid #82251c' : '5px solid #1b8849',
      },
      '&::after': {
        position: 'absolute',
        zIndex: -1,
        content: "''",
        display: 'block',
        border: deliveryStatus === 'taken' ? '5px solid #82251c' : '5px solid #1b8849',
      },
      '& span': {
        position: 'absolute',
        display: 'block',
        width: 225,
        padding: '15px 0',
        backgroundColor:
          deliveryStatus === 'completed' || deliveryStatus === 'taken'
            ? '#c0392b'
            : deliveryStatus === 'new'
            ? '#2ecc71'
            : '#f1c40f',
        boxShadow: '0 5px 10px rgba(0,0,0,.1)',
        color: '#fff',
        textShadow: '0 1px 1px rgba(0,0,0,.2)',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    ribbonTopLeft: {
      top: -10,
      right: -10,
      '&::before, ::after': {
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
      },
      '&::before': {
        top: 0,
        left: -5,
      },
      '&::after': {
        bottom: 0,
        right: 0,
      },
      '& span': {
        left: -25,
        top: 30,
        transform: 'rotate(45deg)',
      },
    },
  })({ deliveryStatus })

const Delivery = ({ delivery }) => {
  const dispatch = useDispatch()
  const [showUnauthorized, setShowUnauthorized] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const selectedDelivery = useSelector(state => state.appReducer.selectedDelivery)
  const driver = useSelector(state => state.appReducer.driver)

  const isSignedInUser = isSignedIn()
  const deliveryStatus =
    delivery.driverId && delivery.driverId !== driver.id ? 'taken' : delivery.status
  const classes = useStyles({ deliveryStatus })

  return !isSignedInUser || (isSignedInUser && driver?.id) ? (
    <>
      <Grow in={true}>
        <div className={classes.container}>
          <div>
            <div className={classes.title}>{delivery.title}</div>
            <div className={classes.label}>
              תאריך: <b>{parseDateString(delivery.date)}</b>
            </div>
            <div className={classes.label}>
              {delivery.toHour} - {delivery.fromHour}
            </div>
            <div className={classes.label}>תשלום: ₪{delivery.cost}</div>
          </div>

          {deliveryStatus === 'new' ? (
            <div className={`${classes.ribbon} ${classes.ribbonTopLeft}`}>
              <span>משלוח חדש!</span>
            </div>
          ) : deliveryStatus === 'completed' || delivery.driverId !== driver.id ? (
            <div className={`${classes.ribbon} ${classes.ribbonTopLeft}`}>
              <span>המשלוח הסתיים</span>
            </div>
          ) : (
            <div className={`${classes.ribbon} ${classes.ribbonTopLeft}`}>
              <span>משלוח בביצוע</span>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: delivery.driverId ? 'flex-end' : 'space-between',
            }}
          >
            {!delivery.driverId ? (
              <Button
                classes={{ root: classes.button }}
                onClick={() => {
                  if (driver.id) {
                    dispatch(Actions.setSelectedDelivery(delivery))
                  } else {
                    setShowUnauthorized(true)
                  }
                }}
              >
                קח את המשלוח
              </Button>
            ) : (
              deliveryStatus !== 'completed' && delivery.driverId === driver.id && (
                <Button
                  className={classes.button}
                  onClick={() => dispatch(Actions.setSelectedDelivery(delivery))}
                >
                  פתח לפרטי משלוח וניווט
                </Button>
              )
            )}
            {isAdmin() && (
              <>
                <IconButton
                  className={classes.smallBtn}
                  onClick={() => dispatch(Actions.editDelivery(delivery))}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  className={classes.smallBtnRed}
                  onClick={() => setShowConfirmation(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </Grow>

      <Confirmation
        isOpen={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleConfirm={() => {
          dispatch(Actions.deleteDelivery(delivery))
          setShowConfirmation(false)
        }}
      />

      <UnRegisteredDialog open={showUnauthorized} handleClose={() => setShowUnauthorized(false)} />

      <DeliveryItemExpanded
        handleClose={() => dispatch(Actions.setSelectedDelivery(null))}
        delivery={
          selectedDelivery?.status === 'new' && selectedDelivery?.id === delivery.id
            ? selectedDelivery
            : null
        }
      />

      <OngoingDeliveryInfo
        delivery={selectedDelivery}
        open={
          ['pickup', 'picked'].includes(selectedDelivery?.status) &&
          selectedDelivery?.id === delivery.id
            ? selectedDelivery
            : null
        }
        handleClose={() => dispatch(Actions.setSelectedDelivery(null))}
      />
    </>
  ) : null
}

export default Delivery
