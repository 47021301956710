import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles, TextField, Button, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../actions'
import CustomDatePicker from './CustomDatePicker'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: 500,
    marginTop: 30,
    borderRadius: 5,
    padding: 20,
    '@media (min-width: 1280px)': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,.7)',
    },
  },
  input: {
    width: '100%',
    height: 55,
  },
  confirmBtn: {
    width: '100%',
    height: 55,
    background: '#3498db',
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
})

const NewDelivery = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.appReducer.deliverySaving)
  const deliveryOnEdit = useSelector(state => state.appReducer.deliveryOnEdit)
  const [deliveryDate, setDeliveryDate] = useState(
    deliveryOnEdit ? moment(deliveryOnEdit.date) : moment()
  )
  const initialState = {
    title: '',
    senderName: '',
    senderPhone: '',
    recipientName: '',
    recipientPhone: '',
    fromHour: '',
    toHour: '',
    pickupAddress: '',
    deliveryAddress: '',
    cost: '',
    note: '',
    status: 'new',
  }
  const [delivery, setDelivery] = useState(deliveryOnEdit ? deliveryOnEdit : initialState)

  const handleFormSubmit = () => {
    dispatch(
      deliveryOnEdit
        ? Actions.updateDelivery({ ...delivery, date: deliveryDate.toISOString(), reload: true })
        : Actions.addDelivery({ ...delivery, date: deliveryDate.toISOString() })
    )
  }

  return (
    <div className={classes.container}>
      <form className={classes.form}>
        <h2>{deliveryOnEdit ? 'ערוך' : 'הוסף'} משלוח</h2>
        <TextField
          defaultValue={delivery.title}
          classes={{ root: classes.input }}
          label="כותרת למשלוח"
          style={{ marginBottom: 20 }}
          variant="outlined"
          onChange={({ target }) => setDelivery({ ...delivery, title: target.value })}
        />
        <div style={{ display: 'flex' }}>
          <TextField
            defaultValue={delivery.senderName}
            classes={{ root: classes.input }}
            label="שם השולח"
            style={{ margin: '0 10px' }}
            variant="outlined"
            onChange={({ target }) => setDelivery({ ...delivery, senderName: target.value })}
          />
          <TextField
            defaultValue={delivery.senderPhone}
            classes={{ root: classes.input }}
            type="number"
            label="טלפון של השולח"
            style={{ margin: '0 10px' }}
            variant="outlined"
            onChange={({ target }) => setDelivery({ ...delivery, senderPhone: target.value })}
          />
        </div>
        <br />
        <br />
        <div style={{ display: 'flex' }}>
          <TextField
            defaultValue={delivery.recipientName}
            classes={{ root: classes.input }}
            label="שם המקבל"
            variant="outlined"
            style={{ margin: '0 10px' }}
            onChange={({ target }) => setDelivery({ ...delivery, recipientName: target.value })}
          />
          <TextField
            defaultValue={delivery.recipientPhone}
            classes={{ root: classes.input }}
            type="number"
            label="טלפון של המקבל"
            style={{ margin: '0 10px' }}
            variant="outlined"
            onChange={({ target }) => setDelivery({ ...delivery, recipientPhone: target.value })}
          />
        </div>
        <br />
        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          תאריך המשלוח
          <CustomDatePicker value={deliveryDate} handleDateChange={setDeliveryDate} />
        </div>
        <br />
        <br />
        <div style={{ display: 'flex' }}>
          <TextField
            defaultValue={delivery.fromHour}
            classes={{ root: classes.input }}
            label="משעה"
            placeholder="00:00"
            variant="outlined"
            style={{ margin: '0 10px' }}
            onChange={({ target }) => setDelivery({ ...delivery, fromHour: target.value })}
          />
          <TextField
            defaultValue={delivery.toHour}
            classes={{ root: classes.input }}
            label="עד שעה"
            placeholder="00:00"
            variant="outlined"
            style={{ margin: '0 10px' }}
            onChange={({ target }) => setDelivery({ ...delivery, toHour: target.value })}
          />
        </div>
        <br />
        <br />
        <TextField
          defaultValue={delivery.pickupAddress}
          classes={{ root: classes.input }}
          label="כתובת איסוף"
          variant="outlined"
          onChange={({ target }) => setDelivery({ ...delivery, pickupAddress: target.value })}
        />
        <br />
        <br />
        <TextField
          defaultValue={delivery.deliveryAddress}
          classes={{ root: classes.input }}
          label="כתובת היעד"
          variant="outlined"
          onChange={({ target }) => setDelivery({ ...delivery, deliveryAddress: target.value })}
        />
        <br />
        <br />
        <TextField
          defaultValue={delivery.cost}
          classes={{ root: classes.input }}
          label="מחיר"
          variant="outlined"
          type="number"
          onChange={({ target }) => setDelivery({ ...delivery, cost: target.value })}
        />
        <br />
        <br />
        <TextField
          defaultValue={delivery.note}
          classes={{ root: classes.input }}
          label="הערה"
          variant="outlined"
          onChange={({ target }) => setDelivery({ ...delivery, note: target.value })}
        />
        <br />
        <br />

        <br />
        {!isLoading ? (
          <Button variant="contained" classes={{ root: classes.confirmBtn }} onClick={handleFormSubmit}>
            שמירה
          </Button>
        ) : (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </form>
    </div>
  )
}

export default NewDelivery
