import React from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as ROUTES from '../consts/routerConsts'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    '& h2': {
      fontSize: '1.5rem !important',
      fontWeight: 'bold'
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    '& button': {
      background: '#3498db',
      color: 'white',
      fontSize: 16,
      flex: 1
    }
  }
})

const UnRegisteredDialog = ({ open, handleClose }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>אופס...</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        על מנת לקחת משלוח עליך להיות רשום למערכת ולהיות מחובר לחשבונך
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => history.push(ROUTES.SIGN_IN)}
          >
            התחברות
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => history.push(ROUTES.SIGN_UP)}
          >
            הרשמה
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default UnRegisteredDialog
