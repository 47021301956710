import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, TextField, Button, CircularProgress } from '@material-ui/core'
import Actions from '../actions'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  signIn: {
    width: 500,
    marginTop: 30,
    borderRadius: 5,
    padding: 20,
    '@media (min-width: 1280px)': {
      boxShadow: '0 0 2px 0 rgba(0,0,0,.7)',
    },
  },
  input: {
    width: '100%',
    height: 55,
  },
  confirmBtn: {
    width: '100%',
    height: 55,
    background: '#3498db',
    fontSize: 16,
    color: 'white'
  },
  forgot: {
    marginTop: 20,
    color: '#3498db',
    cursor: 'pointer',
  },
})

const ResetPassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.appReducer.authLoading)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')

  const handleFormSubmit = e => {
    e.preventDefault()
    if (password.length < 8) {
      dispatch(Actions.showSnackbar({
        snackbarOpen: true,
        snackbarText: 'הסיסמא חייבת להיות באורך 8 תווים לפחות',
        snackbarType: 'error',
        snackbarDuration: 3000,
      }))
    } else {
      dispatch(Actions.requestResetPassword({ code: confirmationCode, username, password }))
    }
  }

  return (
    <form className={classes.container} onSubmit={handleFormSubmit}>
      <div className={classes.signIn}>
        <div style={{ marginBottom: 20 }}>התחברות</div>
        <TextField
          classes={{ root: classes.input }}
          label="קוד אימות (נשלח בSMS)"
          variant="outlined"
          value={confirmationCode}
          onChange={({ target }) => setConfirmationCode(target.value)}
        />
        <br />
        <br />
        <TextField
          classes={{ root: classes.input }}
          label="מספר טלפון"
          variant="outlined"
          value={username}
          onChange={({ target }) => setUsername(target.value)}
        />
        <br />
        <br />
        <TextField
          classes={{ root: classes.input }}
          label="סיסמא חדשה"
          variant="outlined"
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
        <br />
        <br />
        {isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Button type="submit" variant="contained" classes={{ root: classes.confirmBtn }}>
            אפס סיסמא
          </Button>
        )}
      </div>
    </form>
  )
}

export default ResetPassword
