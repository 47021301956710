import { combineEpics } from 'redux-observable'
import * as appEpic from './appEpic'
import * as authEpic from './authEpic'

export default combineEpics(
  ...Object.values({
    ...appEpic,
    ...authEpic
  })
)
