import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { Route, Switch } from 'react-router';
import {
  ConnectedRouter,
  routerMiddleware as createRouterMiddleware,
} from 'connected-react-router';
import Amplify from 'aws-amplify';
import * as ROUTES from './consts/routerConsts';
import rootEpic from './epics';
import rootReducer from './reducers';
import App from './components/App';
import awsExports from './aws-exports';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  direction: 'rtl',
});

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

const root = document.getElementById('root');
const history = createBrowserHistory();

const composeEnhancers = composeWithDevTools({});
const routerMiddleware = createRouterMiddleware(history);
const epicMiddleware = createEpicMiddleware();

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware))
);
epicMiddleware.run(rootEpic);

Amplify.configure(awsExports);

if (root !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <RTL>
            <Switch>
              <Route path={ROUTES.ROOT} component={App} />
            </Switch>
          </RTL>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>,
    root
  );
}
